import * as React from "react"
//import Link from '../components/Link';
//import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const HomePage = () => {
  const slug = "original"
  return (<Layout slug={slug}>
    <Seo title={`${slug} Poetry Kit`} />
  </Layout>
  )
}

export default HomePage
